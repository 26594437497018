<template>
  <div class="h-full" >
    <div class="bottom_space rounded h-full pt-4 bg-white">
      <div class="w-80">
        <div class="card cursor-pointer rounded p-4 h-20 flex items-center bg-white" @click="openResetPss()">
          <p class=" text-text1 heading-3">
            Update Password
          </p>
        </div>
      </div>
    </div>
    <div v-if="resetPasswrod" class="popup_overlay px-4">
        <div class="custom_dialog rounded-lg" style="width: 500px;max-height: 95%;">
          <div class="relative bg-white text-center" v-if="!adddNewPassPopup">
          <div class="p-2 mt-4">
            <p class="heading-4">Please enter OTP sent to your Email</p>
            <div class=" my-3 flex justify-center">
              <div class="w-56 heading-4">
                <TextField
                :isOtp="true"
                :textMaxlength="6"
                :inputId="`otpinputvalueid`"
                :inputext="otpValue"
                :placholderText="``"
                :lableText="''"
                :autoFocus="true"
                :inputType="'text'"
                @inputChangeAction="(data) => otpValue = data"  />
              </div>
            </div>
            <div class="flex justify-end">
              <div v-if="countDown > 0" class="pr-4 text-text1">Resend OTP  <span >in {{countDown}} Sec</span></div>
              <div v-else @click="reSendOtp" class=" cursor-pointer text-primary pr-4">Resend OTP</div>
            </div>
            <div class="p-4">
              <div class="py-1.5 mb-px">
                <button :disabled="otpValue.length < 6"
                :class="otpValue.length < 6 ? 'opacity-30 cursor-not-allowed' : ' '"
                  class="w-full heading-5 font-semibold text-white bg-primary2 rounded-full py-2"
                  large
                  @click="resetPasword1">Next
                </button>
              </div>
              <div class=" text-text2 text-center mt-2">
                <span class=" cursor-pointer font-bold" @click="redirectLogin()">Cancel</span>
              </div>
            </div>
            </div>
          </div>
          <div class="relative bg-white text-center" v-if="adddNewPassPopup">
          <div class="p-2">
            <div class="my-2 text-primary flex justify-center">
              <div class="text-3xl relative">
                <i class="fa-solid fa-key"></i>
              </div>
            </div>
            <div class="pb-3 text-primary"></div>
            <div class=" mb-4">
              <div class="px-5">
                <TextField 
                  :fieldError="currentErr !== ''"
                  @keyPressAction="currentErr = ''"
                  :inputId="`pinsetinpuvalur3`"
                  :inputext="currentValue"
                  :placholderText="`Enter Your Current Password`"
                  :lableText="'Enter Your Current Password'"
                  :autoFocus="false"
                  :inputType="'text'"
                  @inputChangeAction="(data) => currentValue = data"  />
                  <p class="text-error text-left"> {{ currentErr }}</p>
                </div>
              </div>
            <div class=" mb-4">
              <div class="px-5">
                <TextField 
                  :inputId="`pinsetinpuvalur1`"
                  :inputext="setPinValue"
                  :placholderText="`Enter Your Password`"
                  :lableText="'Enter Your Password'"
                  :autoFocus="true"
                  :inputType="'text'"
                  @inputChangeAction="(data) => setPinValue = data"  />
                </div>
              </div>
            <div class=" mb-4">
              <div class="px-5">
                <TextField 
                  :fieldError="confirmPinErr !== ''"
                  @keyPressAction="confirmPinErr = ''"
                  :inputId="`pinsetinpuvalur2`"
                  :inputext="confirmPin"
                  :placholderText="`Re Enter Your Password`"
                  :lableText="'Re Enter Your Password'"
                  :autoFocus="false"
                  :inputType="'text'"
                  @inputChangeAction="(data) => confirmPin = data"  />
                  <p class="text-error text-left"> {{ confirmPinErr }}</p>
                </div>
              </div>
              <div class="py-2 px-5">
                <button :disabled="setPinDisabled"
                  :class="setPinDisabled ? 'opacity-30 cursor-not-allowed' : ' '"
                  class="w-full heading-5 font-semibold text-white bg-primary2 rounded-full py-2"
                  large
                  @click="updatePass">Update
                </button>
                <div class=" text-text2 text-center mt-3">
                  <span class=" cursor-pointer font-bold" @click="redirectLogin()">Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>  
    </div>
  </div>
</template>
<script>
import TextField from '@/View/components/textfield.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    TextField
  },
  mixins: [deboucneMixin],
  props: [],
  data () {
    return {
      passErr: '',
      setPinDisabled: true,
      confirmPinErr: '',
      confirmPin: '',
      currentErr: '',
      currentValue: '',
      setPinValue: '',
      adddNewPassPopup: false,
      countDown: 30,
      otpValue: '',
      resetPasswrod: false,
      regToken: '',
    }
  },
  created() {
  },
  mounted () {
    document.title = 'Password Management'
  },
  watch: {
    currentValue: {
      handler () {
        if (this.currentValue === '') {
          this.currentErr = 'Current Password is required'
          this.setPinDisabled = true
        } else {
          console.log('this.confirmPin', this.confirmPin)
          if (this.confirmPin !== '' && this.confirmPin === this.setPinValue) {
            this.setPinDisabled = false
          } else {
            this.setPinDisabled = true
          }
        }
      },
      deep: true
    },
    confirmPin: {
      handler () {
        if (this.confirmPin !== '' && this.confirmPin === this.setPinValue) {
            this.setPinDisabled = false
            this.confirmPinErr = ''
          } else {
            this.setPinDisabled = true
            if (this.confirmPin !== '' && this.setPinValue !== '') {
              this.confirmPinErr = 'Password does not match'
            }
        }
      }
    },
    setPinValue: {
      handler () {
        if (this.setPinValue !== '' && this.confirmPin === this.setPinValue) {
            this.setPinDisabled = false
            this.confirmPinErr = ''
        } else {
            this.setPinDisabled = true
            if (this.confirmPin !== '' && this.setPinValue !== '') {
              this.confirmPinErr = 'Password does not match'
            }
        }
      }
    },
  },
  methods: {
    // checkCurrent (data) {
    //   console.log('data', data)
    // },
    openResetPss () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Sending OTP.....'})
        ADMINAPI.SendOtpInEmail(
          'sadmin@zizbey.com',
          '',
          response => {
            this.regToken = response.Data
            this.countDown = 30
            this.resetPasswrod = true
            this.countDownTimer()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    reSendOtp () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Resending OTP.....'})
        ADMINAPI.ResendOTP(
          this.regToken,
          (response) => {
            this.countDown = 30
            this.countDownTimer()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    gotoSign () {
      clearTimeout(this.countDownTimerId);
      this.resetPasswrod = false
      this.otpValue = ''
      this.countDown = 30
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.countDownTimerId = setTimeout(() => { // Store the timer ID
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    updatePass () {
      if (this.currentValue === '') {
        this.currentErr = 'Current Password is required'
      }
      if (this.currentValue !== '' && this.setPinValue !== '' && this.confirmPin !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Saving...'})
        ADMINAPI.UpdateLoginPassword(
          this.regToken,
          this.setPinValue,
          this.currentValue,
          response => {
            clearTimeout(this.countDownTimerId);
            this.adddNewPassPopup = false
            this.resetPasswrod = false
            this.confirmPinErr = ''
            this.currentErr = ''
            this.currentValue = ''
            this.setPinValue = ''
            this.confirmPin = ''
            this.otpValue = ''
            this.countDown = 30;
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
      }
    },
    redirectLogin() {
      clearTimeout(this.countDownTimerId)
      this.adddNewPassPopup = false
      this.resetPasswrod = false
      this.confirmPinErr = ''
      this.currentErr = ''
      this.setPinValue = ''
      this.confirmPin = ''
      this.currentValue = ''
      this.otpValue = ''
      this.countDown = 30
    },
    resetPasword1 () {
      // this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      ADMINAPI.VerifiOTP(
        this.regToken,
        this.otpValue,
        () => {
          this.adddNewPassPopup = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: 'OTP verified successfully', color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
    position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 100px!important;
    height: 100px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
